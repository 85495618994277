import { DateTime } from "luxon";
import { useTranslate } from "@tolgee/react";

import { RoleType, CareGiver } from "../../api/generated/graphql";
import EmptySchedule from "@frontend/lyng/assets/svg/empty/A.svg?react";
import { useCareContext } from "../../providers";

import { useGetVisits, Visit } from "./hook";
import { Spinner } from "../../components/common";
import VisitGroups from "./VisitGroups";
import { useMemo } from "react";
import { Button } from "@frontend/lyng/button";
import { Plus } from "@frontend/lyng/assets/icons/24/outline";
import { useNavigate } from "react-router";
import { useFeatureFlag } from "../../providers/FeatureFlags";

export type VisitInfo = {
  id: string;
  from: DateTime;
  to: DateTime;
  name: string;
  clockIn: DateTime | null;
  clockOut: DateTime | null;
  readOnly?: boolean;
  cancelledAt?: string | null;
  caregiver?: Pick<CareGiver, "id" | "firstName" | "lastName">;
  importantInformation?: string | null;
};

export type GroupedVisits = {
  // date is unix timestamp
  [date: number]: VisitInfo[];
};

const useFormatVisits = (visits: Visit[]): GroupedVisits => {
  const { viewer } = useCareContext().state;

  return visits.reduce((group, visit) => {
    const visitStart = DateTime.fromISO(visit.start);
    const date = visitStart.startOf("day");

    if (visitStart < DateTime.local().minus({ hours: 24 })) {
      return group;
    }

    const readOnly =
      !visit.visitorIds.some((id) => id === viewer?.id) &&
      viewer?.roles.some(
        ({ roleType, resourceId }) =>
          roleType === RoleType.Contact &&
          resourceId === visit.careRecipient.id,
      );

    const key = date.toMillis();
    group[key] = group[key] || [];
    group[key].push({
      id: visit.id,
      from: DateTime.fromISO(visit.start),
      to: DateTime.fromISO(visit.start).plus({
        minutes: visit.durationMinutes,
      }),
      clockIn: visit.clockInTime ? DateTime.fromISO(visit.clockInTime) : null,
      clockOut: visit.clockOutTime
        ? DateTime.fromISO(visit.clockOutTime)
        : null,
      name: `${visit.careRecipient.firstName} ${visit.careRecipient.lastName}`,
      caregiver: visit.visitors[0],
      cancelledAt: visit.cancelledAt,
      readOnly,
      importantInformation: visit.importantInformation,
    });
    return group;
  }, {} as GroupedVisits);
};

export const Schedule = () => {
  const { t } = useTranslate();
  const { viewer } = useCareContext().state;
  const ff_caregiverCreateAndUpdateVisits = useFeatureFlag(
    "CaregiverCreateAndUpdateVisits",
  );
  const navigate = useNavigate();

  const canCreateVisits = useMemo(() => {
    return (
      ff_caregiverCreateAndUpdateVisits &&
      viewer?.tenantAccess.offices.some(
        (office) => office.settings.caregiversCanCreateAndUpdateVisits,
      ) &&
      viewer?.roles.some((role) => role.roleType === RoleType.Caregiver)
    );
  }, [viewer, ff_caregiverCreateAndUpdateVisits]);

  const { visits, error, loading } = useGetVisits();
  const groupedVisits = useFormatVisits(visits);

  if (error) return <p>{error.message}</p>;

  if (loading) return <Spinner />;

  return (
    <div className="flex">
      {Object.keys(groupedVisits).length > 0 ? (
        <div className="flex flex-col flex-auto">
          {canCreateVisits && (
            <Button
              className="mx-4 mb-2"
              onClick={() => navigate("visit/new")}
              variant="secondary"
              icon={Plus}
              iconPosition="right"
              text={t("schedule.addVisitButton")}
            />
          )}
          <VisitGroups groupedVisits={groupedVisits} />
        </div>
      ) : (
        <div className="flex h-full w-full flex-col gap-4 px-5 pt-24">
          <EmptySchedule className="max-w-xs self-center" />
          <p className="text-center text-lg font-medium text-title">
            {t("schedule.noVisits")}
          </p>
          {canCreateVisits && (
            <Button
              className="m-auto"
              onClick={() => navigate("visit/new")}
              variant="primary"
              icon={Plus}
              iconPosition="right"
              text={t("schedule.addVisitButton")}
            />
          )}
        </div>
      )}
    </div>
  );
};
