import Check from "../../../assets/check.svg?react";
import { VisitStatus } from "../../../hooks/visitStatus";

type Props = {
  text: string;
  status: VisitStatus;
};

export const VisitPill = ({ text = "algo", status }: Props) => {
  const color =
    status === "away" || status === "over" ? "text-gray-90" : "text-gray-10";
  const background = (() => {
    if (status === "away") return "bg-secondary-70";
    if (status === "over") return "bg-primary-90";
    if (status === "done") return "bg-secondary-800";
    if (status === "cancelled") return "bg-critical-10";
    if (status === "started") return "";
    return "bg-primary-old-50";
  })();

  return (
    <div
      className={`flex flex-row gap-1 py-1 ${
        status === "started" ? "" : "rounded-xl px-2"
      } ${background}`}
    >
      {status === "done" && <Check />}
      <p className={`text-xs font-medium ${color}`}>{text}</p>
    </div>
  );
};
