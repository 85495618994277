import { useEffect } from "react";

type IntersectionObserverProps = IntersectionObserverInit & {
  target: React.RefObject<HTMLElement>;
  onIntersect: () => void;
  enabled?: boolean;
};

export const useIntersectionObserver = ({
  root = null,
  target,
  onIntersect,
  threshold = 0.1,
  rootMargin = "0px",
  enabled = true,
}: IntersectionObserverProps) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onIntersect();
        }
      },
      {
        root,
        rootMargin,
        threshold,
      },
    );

    const { current } = target;
    if (current) {
      observer.observe(current);
    }

    return () => {
      observer.disconnect();
    };
  }, [enabled, root, rootMargin, threshold, target, onIntersect]);
};
