import { User } from "@frontend/lyng/assets/icons/16/outline";
import { Home } from "@frontend/lyng/assets/icons/16/filled";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { Message } from "@frontend/lyng/assets/icons/24/filled";
import { useFeatureFlag } from "../../../providers/FeatureFlags";
import { useCommsContext } from "@frontend/lyng/comms";

export const AppFooter: FunctionComponent = () => {
  const ff_messaging = useFeatureFlag("Messaging");
  const { unreadCount } = useCommsContext();
  return (
    <footer className="fixed bottom-0 left-0 z-10 flex h-20 w-full flex-row items-center justify-center px-5 text-3xl text-title bg-greyscale-800">
      <NavLink className="flex flex-1 items-center justify-center pb-10" to="">
        {({ isActive }) => (
          <>
            {isActive && (
              <div className="absolute h-0.5 w-1/2 top-0 bg-gradient-to-r from-transparent via-primary-500 to-transparent" />
            )}
            <Home className="text-primary-500 z-10 h-6 w-6 items-center" />
          </>
        )}
      </NavLink>
      {ff_messaging && (
        <NavLink
          className="flex flex-1 items-center justify-center pb-10"
          to="/chat"
        >
          {({ isActive }) => (
            <>
              {isActive && (
                <div className="absolute h-0.5 w-1/2 top-0 bg-gradient-to-r from-transparent via-primary-500 to-transparent" />
              )}
              <div className="relative">
                {unreadCount > 0 && (
                  <div className="absolute -top-1 left-7 h-1.5 w-1.5 bg-critical-600 rounded-full" />
                )}
                <Message className="text-primary-500 z-10 h-6 w-6 items-center" />
              </div>
            </>
          )}
        </NavLink>
      )}
      <NavLink
        className="flex flex-1 items-center justify-center pb-10"
        to="/profile"
      >
        {({ isActive }) => (
          <>
            {isActive && (
              <div className="absolute h-0.5 w-1/2 top-0 bg-gradient-to-r from-transparent via-primary-500 to-transparent" />
            )}
            <User className="text-primary-500 z-10 h-6 w-6 items-center" />
          </>
        )}
      </NavLink>
    </footer>
  );
};
