import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslate } from "@tolgee/react";

import { GradientButton } from "../../components/core";

export const VisitNote = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const textAreaRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslate();
  const [visitNote, setVisitNote] = useState("");

  useEffect(() => {
    const visitNote = localStorage.getItem("visitNote|" + id);
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      if (visitNote) {
        textAreaRef.current.innerText = visitNote;
      }
    }
  }, [id]);

  useEffect(() => {
    localStorage.setItem("visitNote|" + id, visitNote);
  }, [visitNote, id]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const textArea = document.getElementById("text-area") as HTMLDivElement;
    const text = textArea.innerText;
    setVisitNote(text);
    navigate(-1);
  };

  return (
    <>
      <div className="pointer-events-none absolute -top-1/3 z-0 flex h-full w-full items-center justify-center bg-gradient-notes" />
      <form
        onSubmit={handleSubmit}
        id="noter-save-form"
        method="POST"
        className="z-10 flex h-full grow flex-col"
      >
        <div
          className="flex h-full flex-1 grow flex-col items-center justify-center px-2 align-middle text-lg text-title focus:outline-none"
          id="text-area"
          ref={textAreaRef}
          contentEditable="true"
        />

        <div className="fixed bottom-0 h-24 w-full grow items-center justify-center bg-main px-6">
          <GradientButton
            buttonType="primary"
            text={t("save")}
            onClick={() => handleSubmit}
          />
        </div>
      </form>
    </>
  );
};
