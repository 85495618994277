import {
  Outlet,
  useMatches,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useEffect } from "react";
import { RoutePages } from "../../../router/Router";
import { analytics } from "../../../analytics";
import { AppHeader } from "./AppHeader";
import { AppFooter } from "./AppFooter";
import {
  NotificationsList,
  useNotificationContext,
} from "@frontend/lyng/notifications";
import {
  Notification,
  PushNotificationClicked,
} from "@frontend/lyng/notifications/types";
import { useViewerQuery } from "../../../api/generated/graphql";
import { match } from "ts-pattern";
import { isCaregiver } from "../../../utils/viewerUtils";
import typewriter from "../../../typewriter/segment";
import classNames from "classnames";

type Navigator = () => {
  route: RoutePages;
  canBack: boolean;
  showTitle: boolean;
  showFooter: boolean;
  showHeader: boolean;
  showNotificationsButton?: boolean;
  skipBottomPadding?: boolean;
  stickyHeader?: boolean;
};
type HandleWithNavigator = { navigator: Navigator };

function isHandleWithNavigator(
  handle: unknown,
): handle is { route: RoutePages } {
  return typeof handle === "object" && handle !== null && "navigator" in handle;
}

export const Layout = () => {
  const { data } = useViewerQuery();
  const matches = useMatches();
  const navigate = useNavigate();
  const { registerForWebPushNotifications } = useNotificationContext();

  useEffect(() => {
    if (data?.viewer) {
      registerForWebPushNotifications(data.viewer.vapidKey, (v) => {
        console.log("registered", v);
      });
    }
  }, [data, registerForWebPushNotifications]);

  const navigator = matches
    .filter((match) => Boolean(isHandleWithNavigator(match.handle)))
    .map((m) => (m.handle as HandleWithNavigator).navigator())
    .pop();

  const location = useLocation();

  useEffect(() => {
    analytics.page();

    // if location contains "push" as a parameter we need to track it
    if (location.search.includes("push")) {
      // push has structure of { Count: number, Type: string }
      const push = new URLSearchParams(location.search).get("push");
      const pushObj: PushNotificationClicked = push
        ? JSON.parse(push)
        : { Count: 0, Type: "Visit" };

      typewriter.pushNotificationClicked(pushObj);
    }
  }, [location]);

  // Should never happen
  if (!navigator) {
    return <>Error</>;
  }

  const handleNotificationCTA = (notification: Notification) => {
    match(notification.ctaData.type)
      .with("visit", () => {
        typewriter.pushNotificationClicked({ Count: 1, Type: "Visit" });
        navigate(`visit/${notification.ctaData.visitInstanceId}`);
      })
      .with("comms", () => {
        typewriter.pushNotificationClicked({ Count: 1, Type: "Comms" });
        navigate(`chat/${notification.ctaData.cid}`);
      })
      .with("none", () => {
        return;
      })
      .exhaustive();
  };

  const allowNotifications = isCaregiver(data?.viewer);

  return (
    <>
      <ScrollRestoration />
      <div className="flex h-screen flex-col justify-between bg-main">
        <div className="flex">
          {navigator.showHeader && (
            <AppHeader
              title={navigator.route || "Default"}
              showTitle={navigator.showTitle}
              canBack={navigator.canBack}
              stickyHeader={navigator.stickyHeader}
            />
          )}
          {allowNotifications && navigator.showNotificationsButton && (
            <div className="flex-end mt-10 mr-4">
              <NotificationsList
                dateSettings={data?.viewer?.tenantSettings}
                mode="mobile"
                clickHandler={handleNotificationCTA}
              />
            </div>
          )}
        </div>
        <div
          className={classNames(
            { "pb-24": !navigator.skipBottomPadding },
            "grow bg-main",
          )}
        >
          {/* This element renders the the matche route from the Router */}
          <Outlet />
        </div>
        {navigator.showFooter && <AppFooter />}
      </div>
    </>
  );
};
