import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useTranslate } from "@tolgee/react";
import { Headline, Paragraph } from "@frontend/lyng/typography";
import { GradientButton } from "./gradientButton/GradientButton";
import { useNavigate } from "react-router";

export const Error = () => {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const error = useRouteError();
  Sentry.captureException(error);

  return (
    <div className="flex h-full flex-col ">
      <div
        className={
          "flex flex-col self-center text-center justify-center flex-1"
        }
      >
        <Headline className="font-poppins text-white" size="xl">
          Whoops! Something went wrong
        </Headline>
        <Paragraph size="m" className={"font-poppins text-white"}>
          Please try again or contact support
        </Paragraph>
      </div>
      <div className="text-poppins fixed bottom-0 w-full pb-8 px-4">
        <GradientButton
          onClick={() => {
            navigate("/");
          }}
          text={t("notFound.goHome")}
          buttonType="primary"
        />
      </div>
    </div>
  );
};
