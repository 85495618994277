import { match, P } from "ts-pattern";
import { CareteamActions, CareteamState, USER_TYPES } from "./Types";
import {
  featureFlags,
  initialFeatureFlags,
  saveFeatureFlag,
} from "./FeatureFlags";

export const getInitialState = (): CareteamState => {
  const featureFlags = initialFeatureFlags;

  return {
    featureFlags,
    viewer: undefined,
    viewerError: undefined,
    selectedTenant: undefined,
  };
};

const CareReducer = (
  state: CareteamState,
  action: CareteamActions,
): CareteamState =>
  match<[CareteamState, CareteamActions], CareteamState>([state, action])
    .with(
      [{ viewer: P._ }, { type: USER_TYPES.SET_VIEWER }],
      ([, { payload }]) => ({
        ...state,
        viewer: payload.viewer,
        selectedTenant: payload.tenant ?? state.selectedTenant,
      }),
    )
    .with(
      [{ viewerError: P._ }, { type: USER_TYPES.SET_VIEWER_ERROR }],
      ([, { payload }]) => ({
        ...state,
        viewerError: payload,
      }),
    )
    .with(
      [{ selectedTenant: P._ }, { type: USER_TYPES.SET_FEATURE_FLAG }],
      ([, { payload }]) => {
        featureFlags.forEach((flag) => {
          saveFeatureFlag(flag, payload[flag]);
        });

        return {
          ...state,
          featureFlags: payload,
        };
      },
    )
    .with(
      [{ selectedTenant: P._ }, { type: USER_TYPES.SET_TENANT }],
      ([, { payload }]) => ({
        ...state,
        selectedTenant: payload,
      }),
    )
    .otherwise(() => state);

export default CareReducer;
