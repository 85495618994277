import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { Address } from "../../api/generated/graphql";
import { hasBirthday } from "../../utils/dateUtils";
import { VisitInfo } from "../../types";
import ImportantInfo from "../../assets/important-info.svg?react";
import { Birthday, PastVisits } from "@frontend/lyng/assets/icons/24/outline";
import { Phone, Careplan } from "@frontend/lyng/assets/icons/24/filled";
import { TopButton } from "../../components/core/topButton/TopButton";
import { Paragraph } from "@frontend/lyng/typography";
import CareRecipientProfile from "../../assets/care-recipient-profile.svg?react";

type CareRecipient = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  email: string | null;
  address: Partial<Address> | null;
};

type Props = {
  visit: VisitInfo;
  careRecipient: CareRecipient;
};

const hasAddress = (address: Partial<Address> | null) =>
  address &&
  (address.addressLine1 ||
    address.addressLine2 ||
    address.city ||
    address.zipCode ||
    address.homeInformation);

const parseAddress = (address: Partial<Address> | null) => {
  if (!address || !hasAddress(address)) return "";
  let parsed = "";
  if (address.addressLine1) {
    parsed += `${address.addressLine1}, `;
  }
  if (address.zipCode) {
    parsed += `${address.zipCode} `;
  }
  if (address.city) {
    parsed += `${address.city}`;
  }
  return parsed;
};

const Detail = ({ visit, careRecipient }: Props) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const name = careRecipient.firstName;

  const address = parseAddress(careRecipient.address);
  const homeInformation = careRecipient.address?.homeInformation;

  return (
    <div className="flex flex-col px-4">
      <div className="flex flex-row pt-10">
        <CareRecipientProfile className="h-16 w-16" />
        <div className="flex grow flex-col gap-1">
          <p className="text-xl font-bold text-title">{visit.name}</p>
          <div className="text-sm text-title">{address}</div>
          <div className="text-sm text-title">{homeInformation}</div>
        </div>
      </div>
      {visit.birthDate && hasBirthday(visit.birthDate) && (
        <div className="w-full rounded-xl mt-4 bg-gradient-to-r from-blue-500 via-green-500 to-yellow-400 p-[2px]">
          <div className="flex rounded-xl h-full px-4 py-4 w-full  bg-main back">
            <Birthday className="h-8 w-8 text-primary-500" />
            <Paragraph className="pl-4 content-center" size="m">
              {t("visitDetails.birthday", { name })}
            </Paragraph>
          </div>
        </div>
      )}
      {visit.importantInformation && (
        <div className="row flex items-center px-4 py-4 bg-primary-900 my-4 rounded-xl">
          <ImportantInfo className="h-8 w-8" />
          <div className="flex grow flex-col pl-4 text-medium text-title">
            <div>{visit.importantInformation}</div>
          </div>
        </div>
      )}
      <div className="mt-4 flex gap-2 z-20">
        <TopButton
          buttonType="primary"
          buttonSize="medium"
          text={t("contacts.title")}
          icon={<Phone className=" text-secondary-500" />}
          onClick={() => navigate(`./contacts/${careRecipient.id}`)}
        />

        <TopButton
          buttonType="primary"
          buttonSize="medium"
          text={t("carePlan.title")}
          icon={<Careplan className="text-secondary-500" />}
          onClick={() => navigate("./careplan")}
        />

        <TopButton
          buttonType="primary"
          buttonSize="medium"
          text={t("pastVisits")}
          icon={<PastVisits className="text-secondary-500" />}
          onClick={() => navigate(`./past-visits/${careRecipient.id}`)}
        />
      </div>
    </div>
  );
};

export default Detail;
