// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

/**
 * Caregiver hits the confirm activities button during complete care flow
 */
export interface ActivitiesConfirmed {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

export interface ActivityUpdated {
  /**
   * Was the activity completed or not completed
   */
  activity_completed: boolean;
  /**
   * Reason for not completing activity. Use Other, not submitted data.
   */
  not_completed_reason?: string;
  [property: string]: any;
}

export interface AppInstalled {
  OS?: OS;
  [property: string]: any;
}

export enum OS {
  Android = "android",
  Ios = "ios",
  Web = "web",
}

/**
 * Caregiver reaches the "Thank you" screen"
 */
export interface CareCompleted {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

/**
 * Caregiver begins care
 */
export interface CareStarted {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

/**
 * Caregiver hits the complete care button on the active visit screen "starts the clock out
 * process"
 */
export interface CompleteCareStarted {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

export interface ContactContacted {
  type: Type;
  [property: string]: any;
}

export enum Type {
  Phone = "phone",
  SMS = "sms",
}

/**
 * User is logged out
 */
export interface LogOut {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

export interface NotificationClicked {
  Count?: number;
  Type?: string;
  [property: string]: any;
}

export interface LoginFailed {
  usingAD: boolean;
  [property: string]: any;
}

export interface LoginStarted {
  usingAD: boolean;
  [property: string]: any;
}

export interface LoginSucceeded {
  usingAD: boolean;
  [property: string]: any;
}

export interface NotificationsAllMarkedRead {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

export interface PushNotificationClicked {
  Count?: number;
  Type?: string;
  [property: string]: any;
}

export interface PushNotificationsEnabled {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

export interface ScreenViewed {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv, { ErrorObject } from "ajv";

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this:
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next'
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 *
 * setTypewriterOptions({ analytics: analytics })
 */
import type {
  AnalyticsSnippet,
  Analytics,
  AnalyticsBrowser,
  Options,
} from "@segment/analytics-next";

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser;

/** The callback exposed by analytics.js. */
export type Callback = () => void;

export type ViolationHandler = (
  message: Record<string, any>,
  violations: ErrorObject[],
) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations,
) => {
  const msg = JSON.stringify(
    {
      type: "Typewriter JSON Schema Validation Error",
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        "Tracking Plan spec.",
      errors: violations,
    },
    undefined,
    2,
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => AnyAnalytics | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: AnyAnalytics;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ allErrors: true, verbose: true });

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: "typescript",
        version: "9.2.0",
      },
    },
  };
}

/**
 * Fires a 'ActivitiesConfirmed' track call.
 *
 * @param ActivitiesConfirmed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function activitiesConfirmed(
  props: ActivitiesConfirmed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Activities_Confirmed",
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "Caregiver hits the confirm activities button during complete care flow",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Activities Confirmed",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'ActivityUpdated' track call.
 *
 * @param ActivityUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function activityUpdated(
  props: ActivityUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Activity_Updated",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      activity_completed: {
        $id: "/properties/activity_completed",
        description: "Was the activity completed or not completed",
        type: "boolean",
      },
      not_completed_reason: {
        $id: "/properties/not_completed_reason",
        description:
          "Reason for not completing activity. Use Other, not submitted data.",
        type: "string",
      },
    },
    required: ["activity_completed"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Activity Updated",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'AppInstalled' track call.
 *
 * @param AppInstalled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function appInstalled(
  props: AppInstalled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "App_Installed",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      OS: {
        $id: "/properties/OS",
        description: "",
        enum: ["android", "ios", "web"],
        type: "string",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "App Installed",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'CareCompleted' track call.
 *
 * @param CareCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function careCompleted(
  props: CareCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Care_Completed",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: 'Caregiver reaches the "Thank you" screen"',
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Care Completed",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'CareStarted' track call.
 *
 * @param CareStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function careStarted(
  props: CareStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Care_Started",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "Caregiver begins care",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Care Started",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'CompleteCareStarted' track call.
 *
 * @param CompleteCareStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function completeCareStarted(
  props: CompleteCareStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Complete_Care_Started",
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      'Caregiver hits the complete care button on the active visit screen "starts the clock out process"',
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Complete Care Started",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'ContactContacted' track call.
 *
 * @param ContactContacted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contactContacted(
  props: ContactContacted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Contact_Contacted",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      type: {
        $id: "/properties/type",
        description: "",
        enum: ["phone", "sms"],
        type: "string",
      },
    },
    required: ["type"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Contact Contacted",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'LogOut' track call.
 *
 * @param LogOut props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function logOut(
  props: LogOut,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Log_out",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "User is logged out",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Log out",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'NotificationClicked' track call.
 *
 * @param NotificationClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function notificationClicked(
  props: NotificationClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Notification_Clicked",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      Count: { $id: "/properties/Count", description: "", type: "integer" },
      Type: { $id: "/properties/Type", description: "", type: "string" },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Notification Clicked",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'LoginFailed' track call.
 *
 * @param LoginFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function loginFailed(
  props: LoginFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Login_Failed",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      usingAD: { $id: "/properties/usingAD", description: "", type: "boolean" },
    },
    required: ["usingAD"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Login Failed",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'LoginStarted' track call.
 *
 * @param LoginStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function loginStarted(
  props: LoginStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Login_Started",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      usingAD: { $id: "/properties/usingAD", description: "", type: "boolean" },
    },
    required: ["usingAD"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Login Started",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'LoginSucceeded' track call.
 *
 * @param LoginSucceeded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function loginSucceeded(
  props: LoginSucceeded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Login_Succeeded",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      usingAD: { $id: "/properties/usingAD", description: "", type: "boolean" },
    },
    required: ["usingAD"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Login Succeeded",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'NotificationsAllMarkedRead' track call.
 *
 * @param NotificationsAllMarkedRead props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function notificationsAllMarkedRead(
  props: NotificationsAllMarkedRead,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Notifications_All_Marked_Read",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Notifications All Marked Read",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'PushNotificationClicked' track call.
 *
 * @param PushNotificationClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pushNotificationClicked(
  props: PushNotificationClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Push_Notification_Clicked",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      Count: { $id: "/properties/Count", description: "", type: "integer" },
      Type: { $id: "/properties/Type", description: "", type: "string" },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Push Notification Clicked",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'PushNotificationsEnabled' track call.
 *
 * @param PushNotificationsEnabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pushNotificationsEnabled(
  props: PushNotificationsEnabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Push_Notifications_Enabled",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Push Notifications Enabled",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'ScreenViewed' track call.
 *
 * @param ScreenViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function screenViewed(
  props: ScreenViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Screen_Viewed",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Screen Viewed",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,

  /**
   * Fires a 'ActivitiesConfirmed' track call.
   *
   * @param ActivitiesConfirmed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  activitiesConfirmed,
  /**
   * Fires a 'ActivityUpdated' track call.
   *
   * @param ActivityUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  activityUpdated,
  /**
   * Fires a 'AppInstalled' track call.
   *
   * @param AppInstalled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  appInstalled,
  /**
   * Fires a 'CareCompleted' track call.
   *
   * @param CareCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  careCompleted,
  /**
   * Fires a 'CareStarted' track call.
   *
   * @param CareStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  careStarted,
  /**
   * Fires a 'CompleteCareStarted' track call.
   *
   * @param CompleteCareStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  completeCareStarted,
  /**
   * Fires a 'ContactContacted' track call.
   *
   * @param ContactContacted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contactContacted,
  /**
   * Fires a 'LogOut' track call.
   *
   * @param LogOut props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  logOut,
  /**
   * Fires a 'NotificationClicked' track call.
   *
   * @param NotificationClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  notificationClicked,
  /**
   * Fires a 'LoginFailed' track call.
   *
   * @param LoginFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  loginFailed,
  /**
   * Fires a 'LoginStarted' track call.
   *
   * @param LoginStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  loginStarted,
  /**
   * Fires a 'LoginSucceeded' track call.
   *
   * @param LoginSucceeded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  loginSucceeded,
  /**
   * Fires a 'NotificationsAllMarkedRead' track call.
   *
   * @param NotificationsAllMarkedRead props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  notificationsAllMarkedRead,
  /**
   * Fires a 'PushNotificationClicked' track call.
   *
   * @param PushNotificationClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pushNotificationClicked,
  /**
   * Fires a 'PushNotificationsEnabled' track call.
   *
   * @param PushNotificationsEnabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pushNotificationsEnabled,
  /**
   * Fires a 'ScreenViewed' track call.
   *
   * @param ScreenViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  screenViewed,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === "string" && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
      const a = analytics();
      if (a) {
        a.track(
          "Unknown Analytics Call Fired",
          {
            method,
          },
          withTypewriterContext(),
        );
      }
    };
  },
});
