import { ChannelList, useChatContext } from "stream-chat-react";
import { useCareContext } from "../../providers";
import {
  ChatterList,
  CommsChannelList,
  CommsChannelPreview,
} from "@frontend/lyng/comms";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFeatureFlag } from "../../providers/FeatureFlags";
import { LoadingBounce } from "@frontend/lyng/loading";
import { Button } from "@frontend/lyng/button";
import { Plus } from "@frontend/lyng/assets/icons/24/outline";
import { usePrivateChannelCreateMutation } from "../../api/generated/graphql";
import { Chatter } from "@frontend/lyng/comms/ChatterList";

export const ChatList = () => {
  const {
    state: { viewer },
  } = useCareContext();

  const ff_messaging = useFeatureFlag("Messaging");
  const [show, setShow] = useState(false);
  const { channel, setActiveChannel } = useChatContext();
  const [privateChannelCreateMutation] = usePrivateChannelCreateMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!channel) return;

    navigate(`/chat/${channel.id}`);
    setActiveChannel(undefined);
  }, [channel, navigate, setActiveChannel]);

  if (!ff_messaging) {
    navigate("/");
    setActiveChannel(undefined);
    return;
  }

  if (!viewer) {
    return (
      <div className="flex justify-center dark:bg-greyscale-900 m-4">
        <LoadingBounce size="m" />
      </div>
    );
  }

  const onChatterClick = async (chatter: Chatter) => {
    const { data } = await privateChannelCreateMutation({
      variables: { input: { member: chatter.id } },
    });

    if (data?.privateChannelCreate) {
      navigate(`/chat/${data.privateChannelCreate.id}`);
    }
  };

  const filters = { members: { $in: [viewer?.id] }, type: "messaging" };
  const options = { presence: true, state: true };

  return (
    <>
      <div className="absolute top-0 right-0 py-8 pr-6 mt-2 z-20">
        <Button
          variant="secondary"
          onClick={() => setShow(true)}
          icon={Plus}
          iconPosition="only"
        />
      </div>
      <ChannelList
        List={CommsChannelList}
        Preview={CommsChannelPreview}
        filters={filters}
        options={options}
        setActiveChannelOnMount={false}
      />
      <ChatterList
        show={show}
        setShow={setShow}
        onChatterClick={onChatterClick}
      />
    </>
  );
};
