import React from "react";
import { AriaAttributes } from "react";
import { AriaNumberFieldProps, useLocale, useNumberField } from "react-aria";
import { useObjectRef } from "@react-aria/utils";
import { useNumberFieldState } from "react-stately";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";
import { Paragraph } from "../../../components/typography";
import classNames from "classnames";

type Props = AriaAttributes & {
  id?: string;
  placeholder?: string;
  alignRight?: boolean;
  value: number;
  onChange: (value: number) => void;
};

export function CurrencyInput({
  id,
  placeholder,
  alignRight,
  onChange,
  value,
  ...ariaProps
}: Props) {
  const { locale } = useLocale();
  const { formatNumber, currencySymbol, currencySide } = useNumberFormatter();

  const ref = React.useRef<HTMLInputElement | null>(null);
  const objectRef = useObjectRef(ref);
  const innerProps: AriaNumberFieldProps = {
    ...ariaProps,
    id,
    placeholder,
  };

  const state = useNumberFieldState({ ...innerProps, locale });
  const { groupProps, inputProps } = useNumberField(
    innerProps,
    state,
    objectRef,
  );

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const cleanAmount = inputValue.replace(/[^\d]/g, "");
    const numericValue = Number(cleanAmount);
    if (!isNaN(numericValue) || cleanAmount === "") {
      onChange(numericValue);
    }
  };

  return (
    <div
      {...groupProps}
      className={classNames("sm:mx-w-xs w-full relative rounded-xl", {
        "shadow-sm": true,
      })}
    >
      {currencySymbol && currencySide === "left" && (
        <div className="left-0 pointer-events-none absolute inset-y-0 flex items-center pl-4">
          <Paragraph size="m">{currencySymbol}</Paragraph>
        </div>
      )}
      <input
        {...inputProps}
        ref={objectRef}
        type="text"
        value={formatNumber(value)}
        className={classNames(
          "flex h-12 w-full items-center text-zinc-100 bg-gray-900 rounded-xl outline-none border focus:border-indigo-700 text-lg focus:bg-black border-zinc-700 placeholder:text-gray-900",
          {
            "text-right": !!alignRight,
            "pl-4 pr-11": currencySymbol && currencySide === "right",
            "pl-11 pr-4": currencySymbol && currencySide === "left",
            "px-4": !currencySymbol,
          },
        )}
        onChange={(e) => handleAmountChange(e)}
      />
      {currencySymbol && currencySide === "right" && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
          <Paragraph size="m">{currencySymbol}</Paragraph>
        </div>
      )}
    </div>
  );
}
