import classNames from "classnames";
import { Spinner } from "../../../components/common";

export type ButtonType = "active" | "inactive";
export type Option = { label: string; value: string; action: () => void };

type Props = {
  options: Option[];
  selectedOption: string;
  loading?: boolean;
  disabled?: boolean;
};

export const ChoiceChips = ({
  options,
  selectedOption,
  loading,
  disabled,
}: Props) => {
  return (
    <>
      {options.map((option, i) => (
        <div
          className={classNames(i === 0 ? "min-w-fit" : "", `w-full`)}
          key={option.value}
        >
          <div
            className={`flex-1 "w-full" h-fit bg-main overflow-hidden rounded-xl p-[1px] gap-2`}
          >
            <button
              className={classNames(
                { "bg-violet-300 text-black": option.value === selectedOption },
                {
                  "bg-gray-800 text-btn-label": option.value !== selectedOption,
                },
                "font-semibold py-3 px-4 gap-3 text-base flex h-full w-full items-center justify-center rounded-xl font-poppins",
              )}
              onClick={option.action}
              disabled={disabled || loading}
            >
              {loading ? <Spinner size="small" /> : <div>{option.label}</div>}
            </button>
          </div>
        </div>
      ))}
    </>
  );
};
