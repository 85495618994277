import { DateTime } from "luxon";
import { CarePlan, TextArea, VisitByIdQuery } from "../api/generated/graphql";
import { Activity, Status, VisitInfo } from "../types";
import { activitiesForVisit } from "./activitiesUtils";

export const getImportantInformationFromCarePlan = (
  carePlan: Exclude<CarePlan, "id">,
) => {
  return (
    carePlan.fields.filter((f) => f.__typename === "TextArea") as TextArea[]
  ).find((f) => f.id === "carePlan.importantInformation")?.value;
};

export type VisitInfoWithActivities = VisitInfo & {
  activities: Activity[];
};

export const getStatus = (
  clockInTime: string | null,
  clockOutTime: string | null,
): Status => {
  if (!clockInTime && !clockOutTime) {
    return "pending";
  }

  if (clockInTime && !clockOutTime) {
    return "clockedIn";
  }
  return "clockedOut";
};

export const visitInfo = (
  visit: NonNullable<VisitByIdQuery["visitById"]>,
): VisitInfoWithActivities => {
  const to = DateTime.fromISO(visit.start).plus({
    minutes: visit.durationMinutes,
  });

  const shouldShowLateClockOutModal = visit.clockInTime
    ? DateTime.fromISO(visit.clockInTime) < to
    : false;

  const visitInfo: VisitInfoWithActivities = {
    id: visit.id,
    from: DateTime.fromISO(visit.start),
    to,
    mileage:
      Number(visit.mileage?.drivenForCareRecipientReported) +
      Number(visit.mileage?.drivenToLocationReported),
    clockIn: visit.clockInTime ? DateTime.fromISO(visit.clockInTime) : null,
    clockOut: visit.clockOutTime ? DateTime.fromISO(visit.clockOutTime) : null,
    cancelledAt: visit.cancelledAt,
    expenses: visit.expenses[0]?.reportedAmount,
    status: getStatus(visit.clockInTime, visit.clockOutTime),
    name: `${visit.careRecipient.firstName} ${visit.careRecipient.lastName}`,
    activities: activitiesForVisit(visit),
    birthDate: visit.careRecipient.birthDate,
    importantInformation: getImportantInformationFromCarePlan(
      visit.careRecipient.carePlan,
    ),
    shouldShowLateClockOutModal,
  };
  return visitInfo;
};
