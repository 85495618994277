import {
  Channel as ChannelView,
  Window,
  MessageList,
  MessageInput,
  useChatContext,
  Thread,
  DateSeparatorProps,
} from "stream-chat-react";

import { useNavigate, useParams } from "react-router";
import { useFeatureFlag } from "../../providers/FeatureFlags";
import { LoadingBounce } from "@frontend/lyng/loading";
import { useDateFormatter } from "@frontend/lyng/utils/dateUtils";
import { useCareContext } from "../../providers";
import { DateTime } from "luxon";

const Loading = () => (
  <div className="flex justify-center dark:bg-greyscale-900 m-4">
    <LoadingBounce size="m" />
  </div>
);

const CustomDateSeparator = (props: DateSeparatorProps) => {
  const {
    state: { viewer },
  } = useCareContext();
  const { formatDate } = useDateFormatter(viewer?.tenantSettings);

  const dateTime = DateTime.fromJSDate(props.date);

  return (
    <div className="flex justify-center items-center py-2">
      <div className="px-3 py-px rounded-lg justify-start items-center gap-2.5 inline-flex">
        <div className="text-center text-xs font-normal font-inclusive leading-none">
          {formatDate(dateTime)}
        </div>
      </div>
    </div>
  );
};

export const Chat = () => {
  const { id } = useParams();
  const { client } = useChatContext();
  const navigate = useNavigate();
  const ff_messaging = useFeatureFlag("Messaging");

  if (!ff_messaging) {
    navigate("/");
    return <Loading />;
  }

  if (!id || !client.wsConnection) {
    return <Loading />;
  }

  const channel = client.channel("messaging", id);

  return (
    <ChannelView channel={channel} DateSeparator={CustomDateSeparator}>
      <Window>
        <div className="justify-center h-screen dark:bg-greyscale-900 pt-24 pb-12">
          <MessageList />
        </div>
        <div className="sticky bottom-0">
          <MessageInput />
        </div>
        <Thread />
      </Window>
    </ChannelView>
  );
};
