import { DateTime } from "luxon";
import { ApolloError } from "@apollo/client";
import {
  RoleType,
  useVisitsByCaregiverIdQuery,
  useVisitsByContactIdQuery,
} from "../../api/generated/graphql";
import { useCareContext } from "../../providers";
import { getImportantInformationFromCarePlan } from "../../utils/visits";

export type Visit = {
  id: string;
  start: string;
  durationMinutes: number;
  visitorIds: string[];
  clockInTime: string | null;
  clockOutTime: string | null;
  importantInformation?: string | null;
  careRecipient: {
    id: string;
    firstName: string | null;
    lastName: string | null;
  };
  visitors: {
    id: string;
    firstName: string | null;
    lastName: string | null;
  }[];
  cancelledAt?: string | null;
};

type Data = {
  visits: Visit[];
  error?: ApolloError;
  loading?: boolean;
};

export const useGetVisits = (): Data => {
  const { viewer } = useCareContext().state;

  const isCaregiver = viewer?.roles.some(
    ({ roleType }) => roleType === RoleType.Caregiver,
  );
  const isContact = viewer?.roles.some(
    ({ roleType }) => roleType === RoleType.Contact,
  );

  const from = DateTime.now().startOf("day").minus({ days: 2 }).toISO();
  if (!from) {
    throw new Error("error formatting from date");
  }

  const to = DateTime.now().endOf("day").plus({ days: 35 }).toISO();
  if (!to) {
    throw new Error("error formatting to date");
  }

  const byCaregiver = useVisitsByCaregiverIdQuery({
    skip: !isCaregiver,
    fetchPolicy: "no-cache",
    variables: {
      caregiverId: viewer?.id ?? "",
      from,
      to,
    },
  });

  const byContact = useVisitsByContactIdQuery({
    skip: !isContact,
    fetchPolicy: "no-cache",
    variables: {
      contactId: viewer?.id ?? "",
      from,
      to,
    },
  });

  const accVisits = [...(byCaregiver.data?.visitsByCaregiverId || [])];
  byContact.data?.visitsByContactId.forEach((visit) => {
    if (!accVisits?.find((v) => v.id === visit.id)) {
      accVisits?.push(visit);
    }
  });

  const visits = accVisits.map((visit) => {
    const importantInformation = getImportantInformationFromCarePlan(
      visit.careRecipient.carePlan,
    );

    return { ...visit, importantInformation };
  });

  return {
    visits: visits,
    error: byCaregiver.error || byContact.error,
    loading: byCaregiver.loading || byContact.loading,
  };
};
