import { Button } from "@frontend/lyng/button";
import Modal from "@frontend/lyng/modal/Modal";
import { Headline, Paragraph } from "@frontend/lyng/typography";
import { useTranslate } from "@tolgee/react";

type VisitConflictModalProps = {
  confirm: () => void;
  cancel: () => void;
  show: boolean;
};

export const VisitConflictModal = ({
  confirm,
  show,
  cancel,
}: VisitConflictModalProps) => {
  const { t } = useTranslate();
  return (
    <div>
      <Modal show={show}>
        <Headline className="text-center py-2" size="l">
          {t("visitWizard.conflict.title")}
        </Headline>
        <Paragraph className="px-8 pb-10 text-center">
          {t("visitWizard.conflict.description")}
        </Paragraph>
        <div className="flex flex-row grow gap-2">
          <Button
            onClick={cancel}
            text={t("cancel")}
            variant="secondary"
            className="flex-1"
          />
          <Button onClick={confirm} text={t("continue")} className="flex-1" />
        </div>
      </Modal>
    </div>
  );
};
