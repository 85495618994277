import { DateTime } from "luxon";
import {
  ActivityStatus,
  Priority,
  VisitByIdQuery,
} from "../api/generated/graphql";
import { Activity } from "../types";

const mapActivitiesFromQuery = (
  visit: NonNullable<VisitByIdQuery["visitById"]>,
): Activity[] => {
  // If the visit is not clocked in, show the activities from the visit definition
  if (!visit.clockInTime) {
    return visit.activities.map((activity) => ({
      id: activity.id,
      priority: activity.priority ?? Priority.Optional,
      title: activity.title,
      lastCompletedAt: activity.lastCompletedAt,
      isDue: activity.isDue,
      schedule: activity.schedule,
      description: activity.description,
      status: ActivityStatus.NotStarted,
      timeOfDayStart: activity.timeOfDayStart
        ? DateTime.fromFormat(activity.timeOfDayStart, "HH:mm")
        : undefined,
      comment: null,
    }));
  }

  // Otherwise, show the activities from the visit log
  return visit.activities.map((activity) => {
    return {
      id: activity.id,
      priority: activity.priority ?? Priority.Optional,
      title: activity.title,
      isDue: activity.isDue,
      lastCompletedAt: activity.lastCompletedAt,
      schedule: activity.schedule,
      description: activity.description,
      status: activity.status ?? ActivityStatus.NotStarted,
      comment: activity.comment,
      timeOfDayStart: activity.timeOfDayStart
        ? DateTime.fromFormat(activity.timeOfDayStart, "HH:mm")
        : undefined,
    };
  });
};

const priorityValue = (priority: Priority): number => {
  switch (priority) {
    case Priority.High:
      return 3;
    case Priority.Medium:
      return 2;
    case Priority.Low:
      return 1;
    case Priority.Optional:
      return 0;
  }
};

export const activitiesForVisit = (
  visit: NonNullable<VisitByIdQuery["visitById"]>,
): Activity[] => {
  type TimedActivity = Omit<Activity, "timeOfDayStart"> & {
    timeOfDayStart: DateTime;
  };

  type Split = {
    timed: TimedActivity[];
    untimed: Activity[];
  };

  const baseSort = (a: Activity, b: Activity) => {
    if (a.priority === b.priority) {
      return a.title.localeCompare(b.title);
    }
    return priorityValue(b.priority) - priorityValue(a.priority);
  };

  const activities = mapActivitiesFromQuery(visit);

  const split = activities.reduce(
    (acc: Split, curr: Activity) => {
      const target = curr.timeOfDayStart ? acc.timed : acc.untimed;
      target.push(curr);
      return acc;
    },
    { timed: [], untimed: [] },
  );

  split.timed.sort((a, b) => {
    const timeDiff =
      a?.timeOfDayStart?.toMillis() - b?.timeOfDayStart?.toMillis();
    if (timeDiff === 0) return baseSort(a, b);
    return timeDiff;
  });

  split.untimed.sort(baseSort);

  return [...split.timed, ...split.untimed];
};
