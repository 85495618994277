import classNames from "classnames";

type Tab<T extends string> = {
  id: T; // also used for translation
  label: string;
  icon?: React.ElementType | null;
};

type Props<T extends string> = {
  tabs: Tab<T>[];
  currentTab: T;
  onChange: (tab: T) => void;
  size?: "sm" | "lg";
};

export function Tabs<T extends string>({
  tabs,
  currentTab,
  onChange,
  size = "lg",
}: Props<T>) {
  return (
    <div
      className={classNames(
        "inline-flex items-start justify-start gap-0.5 ring-1 ring-greyscale-200 dark:ring-greyscale-700 ring-inset p-0.5 bg-transparent ",
        {
          "h-8 rounded-2xl": size === "sm",
          "rounded-xl": size === "lg",
        },
      )}
    >
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={classNames(
            "flex items-center justify-center px-4 gap-2 bg-transparent hover:bg-greyscale-100 dark:hover:bg-greyscale-700 font-medium ring-inset",
            {
              // Tab not selected
              "text-greyscale-700 dark:text-greyscale-300 hover:text-greyscale-900 dark:hover:text-greyscale-100":
                tab.id !== currentTab,

              // Tab is selected
              "text-greyscale-900 dark:text-greyscale-100 ring-1 ring-greyscale-400 dark:ring-greyscale-500 bg-transparent":
                tab.id === currentTab,

              // Tab is small
              "py-1.5 self-stretch text-xs leading-none rounded-2xl":
                size === "sm",

              // Tab is large
              "h-11 py-2 text-base rounded-[10px]": size === "lg",
            },
          )}
          onClick={() => onChange(tab.id)}
          type="button"
          aria-current={tab.id === currentTab ? "page" : undefined}
        >
          <span>{tab.label}</span>
          {tab.icon && size === "lg" ? (
            <tab.icon
              className={classNames(
                {
                  "text-primary-600": tab.id === currentTab,
                  "text-gray-400 group-hover:text-gray-500":
                    tab.id !== currentTab,
                },
                "-ml-0.5 mr-2 h-4 w-4",
              )}
              aria-hidden="true"
            />
          ) : null}
        </button>
      ))}
    </div>
  );
}
