import { useParams } from "react-router-dom";
import { useCareRecipientByIdQuery } from "../../api/generated/graphql";
import { Spinner } from "../../components/common";
import { ContactCard } from "./ContactCard";

export const Contacts = () => {
  const { careRecipientId } = useParams();

  const { data, loading, error } = useCareRecipientByIdQuery({
    variables: { id: careRecipientId ?? "" },
  });

  if (error) return <div className="bg-main text-title">{error.message}</div>;
  if (loading) return <Spinner />;

  const recipient = {
    firstName: data?.careRecipientById?.firstName,
    lastName: data?.careRecipientById?.lastName,
    phone: data?.careRecipientById?.phone,
  };

  return (
    <div className="flex flex-col gap-4 px-4">
      <ContactCard contact={recipient} />
      {data?.careRecipientById?.contacts.map((contact) => {
        if (!contact.deactivatedAt) {
          return <ContactCard key={contact.id} contact={contact} />;
        }
        return null;
      })}
    </div>
  );
};
