import { Label } from "@frontend/lyng/typography";
import { VisitInfoWithActivities } from "../../utils/visits";
import { calculateVisitDuration } from "../../utils/dateUtils";
import { useTranslate } from "@tolgee/react";
import { useGetVisitDetails } from "../../hooks/visitStatus";
import { match } from "ts-pattern";
import classNames from "classnames";
import { useCareContext } from "../../providers";
import { TimeFormat } from "../../api/generated/graphql";

type Props = {
  visit: VisitInfoWithActivities;
};

const Subtitle = ({ visit }: Props) => {
  const { t } = useTranslate();
  const { status, detailsText, pillText, looseTime } = useGetVisitDetails({
    ...visit,
  });

  const subtitle = match(status)
    .with("pending", "soon", () =>
      t("visitDetails.title.scheduled", { time: looseTime }),
    )
    .with("now", () => t("visitDetails.title.now"))
    .with("error", () => t("visitDetails.title.error"))
    .with("missed", () => t("visitDetails.title.missed"))
    .with("late", "done", "away", () => detailsText) // Same as the detail text in the card
    .with("started", "over", "cancelled", () => pillText) // Same as the pill text in the card
    .exhaustive();

  const subtitleColor = match(status)
    .with("soon", "now", () => "text-primary-300")
    .with("late", "over", "away", "missed", () => "text-accent-400")
    .with("cancelled", "error", () => "text-critical-400")
    .with("done", () => "text-secondary-500")
    .otherwise(() => "text-greyscale-400");

  return (
    <label
      id="subtitle"
      className={classNames(
        "text-xs font-normal font-inclusive",
        subtitleColor,
      )}
      htmlFor="subtitle"
    >
      {subtitle}
    </label>
  );
};

export const Title = ({ visit }: Props) => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();
  const isMeridian = viewer?.tenantSettings.timeFormat === TimeFormat.AmPm;

  const when = calculateVisitDuration(visit.from, visit.to, isMeridian, t);
  return (
    <div className="flex flex-col pt-2">
      <Label size="xs">{when}</Label>
      <Subtitle visit={visit} />
    </div>
  );
};
